import useFreeShippingCheck from '@jetshop/core/hooks/useFreeShippingCheck';
import React from 'react';
import t from '@jetshop/intl';
import { Price } from '@jetshop/ui/Price';

export const FreeShipping = ({ cartTotal, ...rest }) => {
  const {
    hasMetLimit,
    untilLimit,
    freeShippingConfigured
  } = useFreeShippingCheck(cartTotal);

  const zeroPrice = {
    exVat: 0,
    incVat: 0,
    vat: 0
  };

  if (!freeShippingConfigured) return null;

  return (
    <div className="free-shipping" {...rest}>
      <label>{t('Left to free shipping')}</label>
      <Price price={hasMetLimit ? zeroPrice : untilLimit} />
    </div>
  );
};
